<template>
  <div class="text-center flex flex-col h-screen">
    <div class="header flex flex-shrink-0 justify-center items-center border-b">
      <router-link to="/login">
        <span class="uppercase text-sm font-semibold tracking-widest pt-1">
          Domestic Data Streamers
        </span>
      </router-link>
    </div>

    <ToastNotification :message="toastMessage"></ToastNotification>

    <div class="px-6 flex flex-col flex-1 justify-center items-center mb-12">
      <h1 class="text-3xl font-light">Workforce</h1>
      <h2 class="text-xl font-light">Forgot your password?</h2>
      <ValidationObserver
        v-slot="{ handleSubmit }"
        class="w-full px-6 mt-20"
        v-if="state === 'idle' || state === 'error'"
      >
        <form
          class="flex flex-col"
          @submit.prevent="handleSubmit(submit)"
          novalidate
        >
          <div class="text-left">
            <ValidationProvider
              :rules="{ required: true, email: true }"
              v-slot="{ errors }"
            >
              <div>
                <input
                  id="email"
                  aria-label="Email"
                  name="Email"
                  type="email"
                  autocomplete="email"
                  class="mt-1 block w-full px-0 border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                  placeholder="Email"
                  v-model="reset.email"
                />
                <div
                  class="h-6 text-sm flex items-center text-red-500 font-light"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
          </div>

          <div class="mt-20">
            <button
              type="submit"
              class="w-56 py-3 rounded-full bg-black text-white outline-none focus:outline-none"
            >
              Send reset email
            </button>

            <div class="mt-4 text-sm">
              <router-link to="/login">Back to login</router-link>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div v-else-if="state === 'loading'" class="mt-20">
        <div class="sk-circle">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
        <p>Sending reset email...</p>
      </div>
      <div v-else-if="state === 'success'" class="mt-20">
        <div class="flex items-center space-x-2">
          <span class="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </span>
          <span>Reset email sent correctly</span>
        </div>
        <div class="mt-20">
          <router-link
            to="/login"
            type="submit"
            class="inline-block w-56 py-3 rounded-full bg-black text-white outline-none focus:outline-none"
          >
            Go back to login
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

import ToastNotification from "@/components/ToastNotification.vue";

import authService from "../services/authService";

setInteractionMode("eager");
extend("email", email);
extend("required", required);

const states = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success",
};

export default {
  components: {
    ToastNotification,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      reset: {
        email: "",
      },
      state: states.IDLE,
      toastMessage: { type: "", content: "" },
    };
  },
  methods: {
    async submit() {
      this.state = states.LOADING;
      this.toastMessage = { type: "", content: "" };

      let response;
      try {
        response = await authService.sendAccountPasswordResetEmail(
          this.reset.email
        );
        this.state = states.SUCCESS;
        console.log(response);
      } catch (error) {
        this.state = states.ERROR;

        this.toastMessage.type = "error";
        this.toastMessage.content = "Couldn't reset password";
        console.error(error.response);
      }
    },
  },
};
</script>

<style scoped>
.header {
  height: 4rem;
}
</style>

<style>
.sk-circle {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
